@use './variables.scss' as *;

* {
  box-sizing: border-box;
}

html {
  font-size: $font-size-mobile;
  line-height: 1.3em;
  @include query-tablet {
    font-size: $font-size-tablet;
  }
  @include query-desktop {
    font-size: $font-size-desktop;
  }
}

body,
#root,
.App {
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  position: relative;
  font-family: 'Raleway', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: $color-grayscale-text;
  font-variant-numeric: lining-nums;
}

body.freeze {
  overflow-y: hidden;
  overflow-x: hidden;
}

main {
  position: relative;
  min-height: calc(100% - $height-header-mobile);
  overflow: hidden;
  background-color: #000;
  margin-bottom: $height-footer-mobile;
  padding-top: $height-header-mobile;

  @include elevation-box-4;
  @include query-mobile-short {
    margin-bottom: initial;
  }

  @include query-tablet {
    padding-top: $height-header-tablet;
    min-height: calc(100% - $height-header-tablet);
    margin-bottom: $height-footer-tablet;
    @include query-tablet-short {
      margin-bottom: initial;
    }
  }
  @include query-desktop {
    padding-top: $height-header-desktop;
    min-height: calc(100% - $height-header-desktop);
    margin-bottom: $height-footer-desktop;
    @include query-desktop-short {
      margin-bottom: initial;
    }
  }
}

.end {
  height: 1px;
  width: 100vw;
  color: transparent;
  background-color: transparent;

  &::before {
    content: '';
  }
}

section {
  position: relative;
  padding-top: $space-6;
  padding-bottom: $space-6;
  width: 100%;
  z-index: 1;
  @include query-tablet {
    padding-top: $space-7;
    padding-bottom: $space-7;
  }
  @include query-desktop {
    padding-top: $space-8;
    padding-bottom: $space-8;
  }
}

.container {
  max-width: $break-desktop;
  text-align: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $space-2;
  padding-right: $space-2;
  @include query-tablet {
    padding-left: $space-3;
    padding-right: $space-3;
  }
  @include query-desktop {
    padding-left: $space-4;
    padding-right: $space-4;
  }

  &--fluid {
    @extend .container;
    max-width: initial;
  }
}

// Typography
h1 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  font-size: 3.5em;
  font-weight: 900;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  @include query-tablet {
    font-size: 4.5em;
  }
}
h2 {
  font-size: 3em;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.1em;
  @include query-tablet {
    font-size: 3.5em;
  }
}
h3 {
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  @include query-tablet {
    font-size: 3em;
  }
}
h4 {
  font-size: 2em;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.2em;
}
h5 {
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.1em;
}
h6 {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.1em;
  @include query-tablet {
    font-size: 1.2em;
  }
}

b {
  font-weight: 700;
}

.regular {
  font-weight: 400 !important;
}

.black {
  font-weight: 900;
}

.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}
.fade-exit {
  opacity: 1;
  position: absolute;
  top: $height-header-mobile;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);

  @include query-tablet {
    top: $height-header-tablet;
  }
  @include query-desktop {
    top: $height-header-desktop;
  }
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: all 300ms ease-out;
}