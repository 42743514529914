@use '../../styles/variables.scss' as *;

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color-grayscale-white;
  height: $height-header-mobile;
  border-bottom: 1px solid #ddd;
  z-index: 10;
  transition: clip-path 0.3s;

  @include query-tablet {
    height: $height-header-tablet;
  }
  @include query-desktop {
    height: $height-header-desktop;
  }

  .container {
    @include flex($direction: column, $justify: flex-start);
    align-content: center;
    height: 100%;
    padding: $space-1 $space-2;

    @include query-tablet {
      padding: 13px $space-4;
    }
    @include query-desktop {
      @include flex($justify: space-between);
      row-gap: 0;
      padding: $space-2 $space-4;
    }

    .left {
      width: 100%;
      padding-top: $space-1;
      padding-bottom: $space-1;
      @include flex($justify: space-between);

      @include query-tablet {
        padding-top: 0;
        padding-bottom: 0;
      }
      @include query-desktop {
        flex-basis: 30%;
        width: initial;
      }

      picture {
        // max-width: 220px;
        // max-height: 40px;

        img {
          width: pxMobile(50);
          aspect-ratio: 50 / 35;
          // max-width: 50px;
          max-height: 35px;

          @include query-tablet {
            width: pxTablet(160);
            // max-width: 160px;
            max-height: 30px;
            aspect-ratio: 300 / 58;
          }
        }
      }

      .toggle {
        width: 50px;
        height: 30px;
        position: relative;
        cursor: pointer;
        @include query-desktop {
          display: none;
        }

        .line1,
        .line2,
        .line3 {
          height: 2px;
          border: 1px solid $color-grayscale-text;
          position: absolute;
          right: 0;
          &::before {
            content: '';
          }
          transition: all 0.3s ease-out;
        }
        .line1 {
          width: 40px;
          top: 5px;
        }
        .line2 {
          width: 20px;
          top: 15px;
        }
        .line3 {
          width: 30px;
          top: 25px;
        }

        &.open {
          .line1 {
            transform: rotate(45deg);
            top: 15px;
            width: 35px;
          }
          .line2 {
            width: 0;
            border: 0;
          }
          .line3 {
            transform: rotate(-45deg);
            width: 35px;
            top: 15px;
          }
        }
      }
    }

    .menu {
      display: none;

      @include query-desktop {
        @include flex;
        flex-basis: 40%;
        width: initial;
        gap: 0 $space-3;
      }

      a {
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-grayscale-medium;

        @include query-desktop {
          font-size: 0.8em;
        }

        &.active,
        &:hover {
          color: $color-theme-aqua;
        }
      }
    }

    .right {
      display: none;

      @include query-desktop {
        flex-basis: 30%;
        width: initial;
        margin-bottom: 0;
        @include flex($direction: row-reverse, $justify: flex-start);
      }

      button {
        text-transform: uppercase;
        padding: 5px 15px;
        border: none;
        cursor: pointer;
        background-color: $color-theme-medium;
        color: $color-grayscale-white;
        @include query-desktop {
          font-size: 0.8em;
        }
      }
    }
  }

  &.expand {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    clip-path: inset(0 0 0);    

    &.expandEnter {
      clip-path: inset(0 0 calc(100vh - $height-header-mobile));

      @include query-tablet {
        clip-path: inset(0 0 calc(100vh - $height-header-tablet));
      }
    }

    .container {
      .menu {
        width: 100%;
        @include flex($direction: column);
        flex-grow: 1;
        gap: $space-4 $space-3;
      }

      .right {
        display: initial;
        width: 100%;
        text-align: center;
        margin-bottom: 20vh;
      }
    }
  }
}
