@use '../../styles/variables.scss' as *;

footer {
  position: fixed;
  z-index: -1;
  bottom: 0;
  width: 100vw;
  background-color: $color-grayscale-white;
  font-size: $font-size-small;
  padding: $space-3 $space-3;
  height: $height-footer-mobile;
  @include flex;
  @include query-mobile-short {
    position: initial;
  }

  @include query-tablet {
    height: $height-footer-tablet;
    padding: $space-3 $space-4;
    @include query-tablet-short {
      position: initial;
    }
  }
  @include query-desktop {
    height: $height-footer-desktop;
    padding: $space-4 $space-3;
    @include query-desktop-short {
      position: initial;
    }
  }

  .container {
    @include flex(column);
    align-content: center;
    gap: $space-4;
    max-width: 700px;
    margin: 0 auto;
    position: relative;

    @include query-desktop {
      @include flex;
      max-width: initial;
    }

    .company {
      color: $color-grayscale-medium;
      text-align: center;

      img {
        width: 166px;
        margin-bottom: $space-2;
      }

      @include query-desktop {
        text-align: left;
        flex-basis: 25%;
      }
    }

    .sitemap {
      @include flex;
      gap: $space-2;

      a {
        text-transform: uppercase;
        text-decoration: none;
        color: $color-grayscale-medium;

        &:hover {
          color: $color-theme-aqua;
        }
      }

      @include query-desktop {
        @include flex(column, center, flex-start);
        flex-basis: 10%;
      }
    }

    .contact {
      gap: $space-2;
      @include flex(column);

      .detail {
        text-align: center;

        .head {
          font-weight: 600;
        }

        .content {
          line-break: auto;
          max-width: auto;
          color: $color-grayscale-medium;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: $color-theme-aqua;
          }
        }
      }

      @include query-desktop {
        flex-grow: 1;
        flex-basis: 40%;
        @include flex(column, center, flex-start);

        .detail {
          text-align: left;
        }
      }
    }

    .icon {
      img {
        width: 270px;
      }

      @include query-desktop {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
