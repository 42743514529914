@use 'sass:math';
@use 'sass:meta';

$break-desktop: 1200px;
$break-tablet: 768px;
$break-mobile: 375px;

$font-size-desktop: 18px;
$font-size-tablet: $font-size-desktop;
$font-size-mobile: $font-size-tablet;
$font-size-small: 0.8em;
$font-size-large: 1.6em;

$color-grayscale-white: #ffffff;
$color-grayscale-light: #f3f3f3;
$color-grayscale-disable: #dddddd;
$color-grayscale-medium: #888888;
$color-grayscale-dark: #444444;
$color-grayscale-text: #222222;
$color-grayscale-black: #000000;

$color-theme-light: #bce2e8;
$color-theme-medium: #3ba7c2;
$color-theme-dark: #182f59;
$color-theme-teal: #2b6c80;
$color-theme-aqua: #25b19f;
$color-accent: #eba358;

$space: 1rem; // D T
$space-0: $space * 0;
$space-1: $space * 0.28; // 5   4
$space-2: $space * 1; // 20  16.8
$space-3: $space * 2.23; // 40  33.5
$space-4: $space * 3.34; // 60  50.1
$space-5: $space * 4.45; // 80  66.8
$space-6: $space * 5.56; // 100 83.4
$space-7: $space * 6.67; // 120 100
$space-8: $space * 8.89; // 160 133.3
$space-10x: $space * 10; // 180 150

$height-header-desktop: 80px;
$height-header-tablet: 70px;
$height-header-mobile: 60px;

$height-footer-desktop: 350px;
$height-footer-tablet: 800px;
$height-footer-mobile: 800px;

@function pxMobile($px) {
  @return math.div(pxStrip($px), pxStrip($break-mobile)) * 100vw;
}
@function pxTablet($px) {
  @return math.div(pxStrip($px), pxStrip($break-tablet)) * 100vw;
}
@function pxDesktop($px) {
  @return math.div(pxStrip($px), pxStrip($break-desktop)) * 100vw;
}

@function emDesktop($num) {
  @return math.div(pxStrip($num), pxStrip($font-size-desktop)) * 1rem;
}
@function emTablet($num) {
  @return math.div(pxStrip($num), pxStrip($tablet-font-size)) * 1rem;
}
@function emMobile($num) {
  @return math.div(pxStrip($num), pxStrip($mobile-font-size)) * 1rem;
}

@function pxStrip($number) {
  @if type-of($number) == number and unit($number) == px {
    @return math.div($number, 1px);
  }
  @return $number;
}

@mixin elevation-box-1 {
  box-shadow: 1px 1px 3px rgba($color-grayscale-black, 0.05),
    3px 3px 13px -2px rgba($color-grayscale-black, 0.1);
}
@mixin elevation-box-2 {
  box-shadow: 1px 1px 3px rgba($color-grayscale-black, 0.05),
    5px 9px 10px -3px rgba($color-grayscale-black, 0.1);
}
@mixin elevation-box-3 {
  box-shadow: 1px 1px 3px rgba($color-grayscale-black, 0.05),
    3px 10px 14px -3px rgba($color-grayscale-black, 0.15),
    3px 7px 25px -3px rgba($color-grayscale-text, 0.26);
}
@mixin elevation-box-4 {
  box-shadow: 1px 1px 3px rgba($color-grayscale-black, 0.07),
    0 10px 17px rgba($color-grayscale-black, 0.15),
    0 16px 30px 3px rgba($color-grayscale-text, 0.31);
}
@mixin elevation-box-5 {
  box-shadow: 2px 2px 3px rgba($color-grayscale-black, 0.07),
    0 14px 23px 4px rgba($color-grayscale-black, 0.47),
    0 18px 33px 12px rgba($color-grayscale-text, 0.29);
}

@mixin elevation-box-6 {
  box-shadow: 2px 2px 3px rgba($color-grayscale-black, 0.07),
    0 23px 25px 8px rgba($color-grayscale-black, 0.55),
    0 33px 39px 29px rgba($color-grayscale-text, 0.4);
}

@mixin elevation-drop-6 {
  filter: drop-shadow(2px 2px 3px rgba($color-grayscale-black, 0.07))
    drop-shadow(0 23px 10px rgba($color-grayscale-black, 0.55))
    drop-shadow(0 33px 15px rgba($color-grayscale-text, 0.4));
}

@mixin elevation-text-1 {
  text-shadow: 2px 2px 4px rgba($color-grayscale-black, 0.3);
}
@mixin elevation-text-2 {
  text-shadow: 2px 2px 7px rgba($color-grayscale-black, 0.5);
}
@mixin elevation-text-3 {
  text-shadow: 3px 3px 13px rgba($color-grayscale-black, 0.6);
}

$gradient: linear-gradient(90deg, #050060 0%, #00667a 50%, #090979 100%);

@mixin query-mobile {
  @media screen and (min-width: $break-mobile) {
    @content;
  }
}
@mixin query-tablet {
  @media screen and (min-width: $break-tablet) {
    @content;
  }
}
@mixin query-desktop {
  @media screen and (min-width: $break-desktop) {
    @content;
  }
}

@mixin query-mobile-short {
  @media screen and (max-height: $height-footer-mobile) and (max-width: ($break-tablet - 1)) {
    @content;
  }
}
@mixin query-tablet-short {
  @media screen and (max-height: $height-footer-tablet) and (max-width: ($break-desktop - 1)) {
    @content;
  }
}
@mixin query-desktop-short {
  @media screen and (max-height: $height-footer-desktop) {
    @content;
  }
}

@mixin flex($direction: row, $justify: center, $items: center, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $items;
  flex-wrap: $wrap;
}
